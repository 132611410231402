<template>
  <ul class="en-m-goods-columns m-padding">
      <!-- 骨架屏加载开始 -->
      <Skeleton v-if="data.length <= 0 && loading" :columns="columns" />
      <!-- 骨架屏加载结束 -->

      <li v-for="(item, index) in data" :key="index" :class="{'bg': columns != 1}" :style="{width:columns == 1 ? '100%' : 'auto'}">
        <!-- 列表格式为 一行一列 -->
        <router-link :to="{path: `/en/goods/detail/${item.id}`,
        query: {pre: (preList.indexOf($route.path) == 1 || item.pre_info.is_pre == 1) ? 1 : 0}}"
        class="goods-columns-1" v-if="columns == 1">
          <span class="rank-num" v-if="isRank">{{index}}</span>
          <span class="discount" v-if="item.off != 0"><span>-{{100 - item.off}}%</span></span>
          <img src="@/assets/goods/rank1.png" alt="" class="rank" v-if="index <= 3 && isRank">
          <img src="@/assets/goods/rank2.png" alt="" class="rank" v-if="index > 3 && isRank">
          <img src="@/assets/goods/like.png" alt="like" class="like" v-if="item.goods_collect" @click.stop.prevent="FavoriteHandle(item.id, index, 2)">
          <img src="@/assets/goods/like_not.png" alt="like" class="like" v-else @click.stop.prevent="FavoriteHandle(item.id, index, 1)">
          <img v-lazy="item.image_url ? item.image_url : item.goods_pic_url" alt="goods" class="goods-img">
          <div class="eleven" v-if="item.merchant_id == 1 && preList.indexOf($route.path) == -1 &&  item.pre_info.is_pre == 0 && elevenShow"></div>
          <div class="goods-info">
            <p class="van-multi-ellipsis--l2">
              <span class="m-mooby" v-if="item.merchant_id == 1 && preList.indexOf($route.path) == -1 && item.pre_info.is_pre == 0">M</span>
              <span class="m-mooby" style="width:55px"
              v-if="preList.indexOf($route.path) != -1 || item.pre_info.is_pre == 1">Pre-Sale</span>
              {{item.goods_info ? item.goods_info.name : ''}}
            </p>
            <div class="goods-price">
              <div v-if="item.pre_info.is_pre == 1 && item.pre_info.pre_min_price != null">
                <p><span>$</span><span>{{item.pre_info.pre_min_price}}</span></p>
                <!-- <p v-if="item.off != 0">${{item.min_price}}</p> -->
              </div>
              <div v-else>
                <p><span>$</span><span>{{(item.activity_min_price *1).toFixed(0) != 0 ? item.activity_min_price : (item.min_price * (item.off == 0 ? 1 : item.off / 100)).toFixed(2)}}</span></p>
                <p v-if="(item.activity_min_price *1).toFixed(0) != 0 ? (item.activity_min_price != item.min_price? true : false) : item.off != 0">${{item.min_price}}</p>
              </div>
              <img @click.prevent="handleCart(item.id,$event)" src="@/assets/index/iocn-gouwu@2x.png" alt="cart">
            </div>
          </div>
        </router-link>

        <!-- 列表格式为 一行二列 -->
        <router-link :to="{path: `/en/goods/detail/${item.id}`,
        query: {pre: (preList.indexOf($route.path) == 1 || item.pre_info.is_pre == 1) ? 1 : 0}}"
        class="goods-columns-2" v-else-if="columns == 2">
          <span class="discount" v-if="item.off != 0"><span>-{{100 - item.off}}%</span></span>
          <img v-lazy="item.image_url ? item.image_url : item.goods_pic_url" alt="goods" class="goods-img">
          <div class="eleven" v-if="item.merchant_id == 1 && preList.indexOf($route.path) == -1 &&  item.pre_info.is_pre == 0 && elevenShow"></div>
          <img src="@/assets/goods/like.png" alt="like" class="like" v-if="item.goods_collect" @click.stop.prevent="FavoriteHandle(item.id, index, 2)">
          <img src="@/assets/goods/like_not.png" alt="like" class="like" v-else @click.stop.prevent="FavoriteHandle(item.id, index, 1)">
          <p class="van-multi-ellipsis--l2">
              <span class="m-mooby" v-if="item.merchant_id == 1 && preList.indexOf($route.path) == -1 && item.pre_info.is_pre == 0">M</span>
              <span class="m-mooby" style="width:55px"
              v-if="preList.indexOf($route.path) != -1 || item.pre_info.is_pre == 1">Pre-Sale</span>
            <span>{{item.goods_info ? item.goods_info.name : ''}}</span>
          </p>
          <div class="goods-price" v-if="item.pre_info.is_pre == 1 && item.pre_info.pre_min_price != null">
            <span><span>$</span><span>{{item.pre_info.pre_min_price}}</span></span>
            <!-- <span v-if="item.off != 0">${{item.min_price}}</span> -->
            <img @click.prevent="handleCart(item.id,$event)" src="@/assets/index/iocn-gouwu@2x.png" alt="cart">
          </div>
          <div class="goods-price" v-else>
            <span><span>$</span><span>{{(item.activity_min_price *1).toFixed(0) != 0 ? item.activity_min_price : (item.min_price * (item.off == 0 ? 1 : item.off / 100)).toFixed(2)}}</span></span>
            <span v-if="(item.activity_min_price *1).toFixed(0) != 0 ? (item.activity_min_price != item.min_price? true : false) : item.off != 0">${{item.min_price}}</span>
            <img @click.prevent="handleCart(item.id,$event)" src="@/assets/index/iocn-gouwu@2x.png" alt="cart">
          </div>
        </router-link>

        <!-- 列表格式为 一行三列 -->
        <router-link :to="{path: `/en/goods/detail/${item.id}`,
        query: {pre: (preList.indexOf($route.path) == 1 || item.pre_info.is_pre == 1) ? 1 : 0}}"
        class="goods-columns-3" v-else>
          <span class="discount" v-if="item.off != 0"><span>-{{100 - item.off}}%</span></span>
          <img v-lazy="item.image_url ? item.image_url : item.goods_pic_url" alt="goods" class="goods-img">
          <div class="eleven" v-if="item.merchant_id == 1 && preList.indexOf($route.path) == -1 &&  item.pre_info.is_pre == 0 && elevenShow"></div>
          <img src="@/assets/goods/like.png" alt="like" class="like" v-if="item.goods_collect" @click.stop.prevent="FavoriteHandle(item.id, index, 2)">
          <img src="@/assets/goods/like_not.png" alt="like" class="like" v-else @click.stop.prevent="FavoriteHandle(item.id, index, 1)">
          <p class="van-multi-ellipsis--l2">
              <span class="m-mooby" v-if="item.merchant_id == 1 && preList.indexOf($route.path) == -1 && item.pre_info.is_pre == 0">M</span>
              <span class="m-mooby" style="width:55px"
              v-if="preList.indexOf($route.path) != -1 || item.pre_info.is_pre == 1">Pre-Sale</span>
            <span>{{item.goods_info ? item.goods_info.name : ''}}</span>
          </p>
          <div class="goods-price">
            <div v-if="item.pre_info.is_pre == 1 && item.pre_info.pre_min_price != null">
              <p><span>$</span><span>{{item.pre_info.pre_min_price}}</span></p>
              <!-- <p v-if="item.off != 0">${{item.min_price}}</p> -->
            </div>
            <div v-else>
              <p><span>$</span><span>{{(item.activity_min_price *1).toFixed(0) != 0 ? item.activity_min_price : (item.min_price * (item.off == 0 ? 1 : item.off / 100)).toFixed(2)}}</span></p>
              <p v-if="(item.activity_min_price *1).toFixed(0) != 0 ? (item.activity_min_price != item.min_price? true : false) : item.off != 0">${{item.min_price}}</p>
            </div>
            <img v-if="cart" @click.prevent="handleCart(item.id,$event)" src="@/assets/index/iocn-gouwu@2x.png" alt="cart">
          </div>
        </router-link>
      </li>
      <Nothing type="nothing" v-if="data.length <= 0 && aloading" />
       
      <li class="goods-columns-2 hide-columns" :class="{'goods-columns-3': columns == '3'}"></li>
      <li class="goods-columns-2 hide-columns" :class="{'goods-columns-3': columns == '3'}"></li>
  </ul>
</template>

<script>
import Nothing from './nothing'
import Skeleton from './skeleton'

import { favoriteGoods } from '@/api/en/favorite.js'
export default {
  name:'GoodsColumns',
  components: { Nothing, Skeleton },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
    columns: {
      type: Number,
      default: 2,
    },
    priceLine: {
      type: Boolean,
      default: true
    },
    cart: {
      type: Boolean,
      default: true
    },
    isRank: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    aloading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      preList: ['/en/presale', '/en/subsale'],
      elevenShow: true // 圣诞活动边框显示
    }
  },
  methods:{
    handleCart(goods,e){
      this.$emit('cartClick', goods, e)
    },
    FavoriteHandle(id, index, n) {
      favoriteGoods(id, {status: n}).then(res => {
        if(res) {
          if(this.data[index].goods_collect == null) {
            this.$set(this.data[index], 'goods_collect', 1)
          } else {
            this.$set(this.data[index], 'goods_collect', null)
          }
          this.$notify({ type: 'success', message: res.msg})
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.en-m-goods-columns {
 width: 100%;
 display: flex;
 flex-wrap: wrap;
 justify-content: space-between;
 position: relative;
 z-index: 9;
 padding-top:10px;
 >li{position: relative}
 .bg {
   margin-bottom: 16px;
   background: #fff;
   border-radius: 5px;
 }
 .goods-columns-1 {
   background: #fff;
   width: 343px;
   display: flex;
   box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
   border-radius:5px;
   margin-bottom: 25px;
   padding:10px;
   background: #fff;
   position: relative;
   .discount{display: block;width:35px;height:24px;position: absolute;top:0px;left:12px;background-color: #F94B00;text-align: center;line-height: 24px;
  color:#fff;border-top-left-radius: 5px;
    &::after{content: '';display: block;width:0;height:0;border:12px solid #F94B00;border-right-color: transparent;position: absolute;
    top:0;right:-12px;z-index: 0;border-radius: 5px}
    >span{position: relative;z-index: 9;font-size: 13px;}
  }
  .eleven {
     position: absolute;
     top: -10px;
     left: 10px;
     width: 120px;
     height: 120px;
     border-radius:5px;
     background: url('../../assets/index/eleven_en.png') no-repeat center;
     background-size: 100% 100%;
   }
   .like{position: absolute;bottom:20px;left:95px;width:25px;height:25px;z-index: 2;}
   .goods-img {
     width: 120px;
     height: 120px;
     border-radius:5px;
     margin-right: 11px;
     margin-top:-20px
   }
   .goods-info {
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     flex: 1;
     padding-top: 10px;
     &>p {
      font-size: 14px;
      letter-spacing: 0.31px;
      line-height: 20px;
      font-weight: 400;
      height: 40px;
      color: #333;
     }
   }
   .rank{position: absolute;top:-5px;left:10px;width:22px;height:28px;z-index: 9;}
   .rank-num{position: absolute;top:-4px;left:16px;color:#fff;z-index:10}
   .goods-price {
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin:6px 0  6px 0;
     font-size: 12px;
     &>div {
       display: flex;
       align-items: center;
       &>p:nth-of-type(1){
          color: #ED2A24;
          margin-right: 4px;
          &>span:nth-of-type(1){
            vertical-align: 1px;
          }
          &>span:nth-of-type(2){
            font-size: 18px;
          }
        }
        &>p:nth-of-type(2){
          margin-top: 2px;
          color: #999999;
          text-decoration:line-through;
        }
     }
     &>img {
        width: 40px;
        height: 40px;
      }
   }
 }
  .goods-columns-2 {
    background: #fff;
    width: 166px;
    box-shadow:0px 10px 15px 0px rgba(217,217,217,0.15);
    border-radius:5px;
    margin-bottom: 16px;
    background: #fff;
    .discount{display: block;width:50px;height:24px;position: absolute;top:0px;left:0;background-color: #F94B00;text-align: center;line-height: 24px;
  color:#fff;border-top-left-radius: 5px;
    &::after{content: '';display: block;width:0;height:0;border:12px solid #F94B00;border-right-color: transparent;position: absolute;
    top:0;right:-12px;z-index: 0;border-radius: 5px}
    >span{position: relative;z-index: 9;font-size: 16px;}
  }
    .eleven {
      position: absolute;
      top: 0;
      left: 0;
      width: 166px;
      height: 172px;
      border-radius:5px 5px 0 0;
      background: url('../../assets/index/eleven_en.png') no-repeat center;
      background-size: 100% 100%;
    }
    &>img {
      width: 166px;
      height: 172px;
      border-radius:5px 5px 0px 0px;
    }
    &>p {
      width: 166px;
      font-size: 12px;
      letter-spacing: 0.31px;
      line-height: 18px;
      color: #333;
      padding:0 5px;
    }
    .like{position: absolute;top:140px;left:130px;width:24px;height:24px;z-index: 2;}
    .goods-price {
      margin-top: 7px;
      font-size: 12px;
      padding:0 5px 10px 5px;
      &>span:nth-of-type(1){
        color: #ED2A24;
        &>span:nth-of-type(1){
          vertical-align: 1px;
        }
        &>span:nth-of-type(2){
          font-size: 16px;
        }
      }
      &>span:nth-of-type(2){
        margin-left: 5px;
        color: #999999;
        font-size: 10px;
        text-decoration:line-through ;
      }
      &>img{position: absolute;right:0px;bottom:4px;width:35px;height:35px}
    }
 }
 .goods-columns-3 {
    background: #fff;
    width: 108px;
    box-shadow:0px 10px 15px 0px rgba(217,217,217,0.15);
    border-radius:5px;
    margin-bottom: 16px;
    position: relative;
    display: inline-block;
    .discount{display: block;width:50px;height:24px;position: absolute;top:0px;left:0;background-color: #F94B00;text-align: center;line-height: 24px;
  color:#fff;border-top-left-radius: 5px;
    &::after{content: '';display: block;width:0;height:0;border:12px solid #F94B00;border-right-color: transparent;position: absolute;
    top:0;right:-12px;z-index: 0;border-radius: 5px}
    >span{position: relative;z-index: 9;font-size: 16px;}
  }
    .eleven {
      position: absolute;
      top: 0;
      left: 0;
      width: 108px;
      height: 108px;
      border-radius:5px 5px 0 0;
      background: url('../../assets/index/eleven.png') no-repeat center;
      background-size: 100% 100%;
    }
    &>img {
      width: 108px;
      height: 108px;
      border-radius:5px 5px 0px 0px;
    }
    &>p {
      width: 108px;
      font-size: 12px;
      letter-spacing: 0.31px;
      line-height: 18px;
      color: #333;
      padding: 0 2px;
    }
    .like{position: absolute;top:75px;right:10px;width:25px;height:25px;z-index: 2;}
    .goods-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin:12px 0  6px 0;
      font-size: 12px;
      padding: 0 2px;
      &>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &>p:nth-of-type(1){
            color: #ED2A24;
            &>span:nth-of-type(1){
              vertical-align: 1px;
            }
            &>span:nth-of-type(2){
              font-size: 14px;
            }
        }
        &>p:nth-of-type(2){
          margin-top: 2px;
          color: #999999;
          text-decoration:line-through ;
        }
      }
      &>img {
          width: 30px;
          height: 30px;
        }
    }
  }
  .hide-columns {
    height: 0;
    opacity: 0;
    margin: 0;
  }
}
</style>